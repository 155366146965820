import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminItem } from '../../../../../Store/Store/Item/AdminItem';
import { useNavigate } from 'react-router-dom';
import { M_STORE } from '../../../../../BaseUrl';
import toast from 'react-hot-toast';
import { getGenMaster } from '../../../../../Store/Store/GenralMaster/GenMaster';
import { getMainStock } from '../../../../../Store/Store/Stock/getMainStock';
import { Dropdown } from 'primereact/dropdown';

const ItemModal = ({ show, mode, EditItem, handleClose, handleSave, isOrder, tag_number }) => {
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(true);
    const [ItemNo, setItemNo] = useState(null);
    const navigate = useNavigate()
    const [filter, setFilter] = useState({
        date: {
            start: null,
            end: null
        }
    });
    useEffect(() => {
        if (localStorage.getItem('PAY_USER_TOKEN') === null) {
            navigate("/user/login");
        } else if (localStorage.getItem('VI_PRO') !== `${M_STORE}`) {
            toast.error('Access Denied. You do not have permission to view this product. Please contact your administrator for assistance.')
            navigate("/user/login");
        }
        if (disable === true) {
            fetchData();
        }
    }, [navigate, disable, filter]);
    useEffect(() => {
        if (show) {
            dispatch(getGenMaster({ tag_id: 17 }));
        } else {
            dispatch(getGenMaster({ tag_id: 15 }));
        }
    }, [show]);
    const fetchData = () => {
        const bodyFormData = new URLSearchParams();
        bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'))
        bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'))
        bodyFormData.append('filter', JSON.stringify(filter))
        dispatch(getMainStock(bodyFormData));
        setDisable(false);
    }
    const stock = useSelector((state) => state.getMainStock?.user?.data || []);
    const itemDetails = useSelector((state) => state.getAdminItem?.user?.data || []);
    const categories = useSelector((state) => state.getGenMaster?.user?.data || []);
    const [formState, setFormState] = useState({
        item_id: null,
        item_name: '',
        unit: '',
        m_code: '',
        quantity: 0,
        rate: 0,
        amount: 0,
        discount: 0,
        discount_amount: 0,
        sp_discount: 0,
        sp_discount_amount: 0,
        taxable_amount: 0,
        gst: 0,
        gst_amount: 0,
        total_amount: 0,
        remarks: ''
    });
    const [error, setError] = useState({});
    const [entity, setEntity] = useState([]);
    const [balanceQty, setbalanceQty] = useState()

    useEffect(() => {
        const filteredData = itemDetails?.find((it) => it?._id === ItemNo);
        if (tag_number === 11) {
            setFormState({
                ...formState,
                item_name: filteredData?.name,
                item_id: filteredData?._id,
                gst: filteredData?.gst_percentage,
                m_code: filteredData?.mcode,
                unit: filteredData?.unit?.name
            });
        } else {
            const getStock = stock?.store_items?.find((it) => it?.item_id === ItemNo);
            setFormState({
                ...formState,
                item_name: filteredData?.name,
                item_id: filteredData?._id,
                m_code: filteredData?.mcode,
                unit: filteredData?.unit?.name
            });

            setbalanceQty(getStock?.balance)
        }
    }, [itemDetails, ItemNo, tag_number, balanceQty])

    // useEffect(() => {
    //     const filteredData = stock?.store_items?.find((it) => it?.item_id === ItemNo);
    //     console.log(filteredData, "lll");

    //     setFormState({
    //         ...formState,
    //         m_code: filteredData?.m_code,
    //         unit: filteredData?.unit
    //     });
    //     setbalanceQty(filteredData?.balance)
    // }, [balanceQty, formState.item_id])

    useEffect(() => {
        if (mode === 'edit' && EditItem && tag_number === 17) {
            const filteredData = stock?.store_items?.find((it) => it?.item_id === EditItem.item_id);;
            setFormState({
                item_id: EditItem.item_id,
                item_name: EditItem.item_name,
                unit: EditItem.unit,
                m_code: EditItem.m_code,
                quantity: EditItem.quantity,
                b_quantity: filteredData?.balance,
                item_brand: EditItem.item_brand,
                item_domain: EditItem.item_domain,
                Categories_name: EditItem.Categories,
                remarks: EditItem.remarks
            });
        }
    }, [EditItem, mode]);
    useEffect(() => {
        dispatch(getAdminItem());
    }, [dispatch]);

    useEffect(() => {
        if (itemDetails.length > 0) {
            setEntity(itemDetails);
        }
    }, [itemDetails]);

    useEffect(() => {
        if (mode === 'edit' && EditItem && tag_number === 11) {
            setFormState({
                item_id: EditItem.item_id,
                item_name: EditItem.item_name,
                unit: EditItem.unit,
                m_code: EditItem.m_code,
                quantity: EditItem.quantity,
                rate: EditItem.rate,
                amount: EditItem.amount,
                discount: EditItem.discount,
                discount_amount: EditItem.discount_amount,
                sp_discount: EditItem.sp_discount,
                sp_discount_amount: EditItem.sp_discount_amount,
                gst: EditItem.gst,
                gst_amount: EditItem.gst_amount,
                total_amount: EditItem.total_amount,
                item_brand: EditItem.item_brand,
                item_domain: EditItem.item_domain,
                remarks: EditItem.remarks
            });
        }
    }, [EditItem, mode]);

    const resetForm = () => {
        setFormState({
            item_id: '',
            item_name: '',
            unit: '',
            m_code: '',
            quantity: 0,
            rate: 0,
            amount: 0,
            discount: 0,
            discount_amount: 0,
            sp_discount: 0,
            sp_discount_amount: 0,
            gst: 0,
            gst_amount: 0,
            total_amount: 0,
            remarks: ''
        });
        setError({});
    };

    // Modal close handler
    const handleModalClose = () => {
        mode === 'add' && resetForm();
        handleClose();
    };

    // Change handler for form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);

        setFormState((prevState) => ({
            ...prevState,
            [name]: value
        }));
        setError({});
    };

    // Calculate amounts based on input values
    useEffect(() => {
        const calculateAmounts = () => {
            const { quantity, rate, discount, sp_discount, gst } = formState;
            const Amount = parseFloat(quantity) * parseFloat(rate) || 0;
            const discountAmount = Amount * (parseFloat(discount) / 100) || 0;
            const spDiscountAmount = (Amount - discountAmount) * (parseFloat(sp_discount) / 100) || 0;
            const taxableAmount = Amount - discountAmount - spDiscountAmount;
            const gstAmount = taxableAmount * (parseFloat(gst) / 100) || 0;
            const totalAmount = taxableAmount + gstAmount;

            setFormState((prevState) => ({
                ...prevState,
                amount: Amount.toFixed(2),
                discount_amount: discountAmount.toFixed(2),
                sp_discount_amount: spDiscountAmount.toFixed(2),
                taxable_amount: taxableAmount.toFixed(2),
                gst_amount: gstAmount.toFixed(2),
                total_amount: totalAmount.toFixed(2)
            }));
        };
        calculateAmounts();
    }, [formState.quantity, formState.rate, formState.discount, formState.sp_discount, formState.gst]);

    const handleItemsChange = (e) => {
        setItemNo(e.value);
    };
    const Itemsoption = itemDetails?.map(it => ({
        label: it?.name,
        value: it?._id
    }));

    // Save the item (add or edit)
    const saveItem = (addMore = false) => {
        const itemData = { ...formState };

        if (mode === 'add') {
            console.log(itemData);

            handleSave([itemData], 'add');
        } else {
            handleSave(itemData, EditItem._id, 'edit');
        }

        if (addMore) {
            resetForm();
        } else {
            handleModalClose();
        }
    };

    return (
        <Modal show={show} onHide={handleModalClose} size="lg" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{mode === 'edit' ? 'Edit Item' : 'Add Item'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <div className="input-block local-forms">
                            <label>Item Name<span className="login-danger">*</span></label>
                            <Dropdown
                                value={ItemNo || formState.item_id}
                                options={Itemsoption}
                                onChange={handleItemsChange}
                                placeholder="select Item"
                                filter
                                filterBy="label"
                                appendTo="self"
                                className="w-100 multi-prime-react model-prime-multi"
                            />
                            {error.item_error && <div className="error">{error.item_error}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-block local-forms">
                            <label>
                                Unit
                            </label>
                            <input
                                className="form-control"
                                name="unit"
                                value={formState?.unit}
                                onChange={handleChange}
                                disabled
                            />
                            <div className="error"></div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="input-block local-forms">
                            <label>M. Code</label>
                            <input
                                type='text'
                                className="form-control"
                                name="m_code"
                                value={formState?.m_code}
                                onChange={handleChange}
                                disabled
                            />

                        </div>
                    </div>
                    {
                        tag_number === 17 && <div className="col-md-6">
                            <div className="input-block local-forms">
                                <label>
                                    Stock Quantity
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="b_quantity"
                                    value={balanceQty}
                                    onChange={handleChange}
                                    disabled
                                />
                            </div>
                        </div>
                    }
                    {
                        tag_number === 11 ? <><div className="col-md-6">
                            <div className="input-block local-forms">
                                <label>Quantity<span className="login-danger">*</span></label>
                                <input type="number" className="form-control" name="quantity" value={formState.quantity} onChange={handleChange} />
                                {error.quantity_error && <div className="error">{error.quantity_error}</div>}
                            </div>
                        </div> <div className="col-md-6">
                                <div className="input-block local-forms">
                                    <label>Rate<span className="login-danger">*</span></label>
                                    <input type="number" className="form-control" name="rate" value={formState.rate} onChange={handleChange} />
                                    {error.rate_error && <div className="error">{error.rate_error}</div>}
                                </div>
                            </div> </> : <div className="row">
                            <div className="col-md-6">
                                <div className="input-block local-forms">
                                    <label>Require Quantity<span className="login-danger">*</span></label>
                                    <input type="number" className="form-control" name="quantity" value={formState.quantity} onChange={handleChange} />
                                    {error.quantity_error && <div className="error">{error.quantity_error}</div>}
                                </div>
                            </div>
                        </div>
                    }
                    {
                        tag_number === 11 && <>
                            <div className="col-12">
                                <div className="input-block local-forms">
                                    <label>
                                        Amount
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="amount"
                                        value={formState.amount}
                                        disabled
                                    />
                                    <div className="error"></div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="input-block local-forms">
                                    <label>
                                        Discount
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="discount"
                                        value={formState?.discount}
                                        onChange={handleChange}
                                        disabled={isOrder}
                                    />
                                    <div className="error"></div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="input-block local-forms">
                                    <label>
                                        Discount Amount
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="discount_amount"
                                        value={!isOrder ? formState.discount_amount : 0}
                                        disabled
                                    />
                                    <div className="error"></div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="input-block local-forms">
                                    <label>
                                        Sp. Discount
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="sp_discount"
                                        value={!isOrder ? formState.sp_discount : 0}
                                        onChange={handleChange}
                                        disabled={isOrder}
                                    />
                                    <div className="error"></div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="input-block local-forms">
                                    <label>
                                        SP. Discount Amount
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="sp_discount_amount"
                                        value={!isOrder ? formState.sp_discount_amount : 0}
                                        disabled
                                    />
                                    <div className="error"></div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="input-block local-forms">
                                    <label>
                                        Taxable
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="taxable_amount"
                                        value={!isOrder ? formState.taxable_amount : 0}
                                        disabled
                                    />
                                    <div className="error"></div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="input-block local-forms">
                                    <label>
                                        GST
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="gst"
                                        value={formState?.gst}
                                        onChange={handleChange}
                                        disabled={isOrder}
                                    />
                                    <div className="error"></div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="input-block local-forms">
                                    <label>
                                        GST Amount
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="gst_amount"
                                        value={!isOrder ? formState.gst_amount : 0}
                                        disabled
                                    />
                                    <div className="error"></div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="input-block local-forms">
                                    <label>
                                        Total Amount
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="total_amount"
                                        value={!isOrder ? formState.total_amount : 0}
                                        disabled
                                    />
                                    <div className="error"></div>
                                </div>
                            </div>
                        </>
                    }

                    {
                        tag_number === 17 && <div className="row">
                            <div className="col-md-6">
                                <div className="input-block local-forms">
                                    <label>
                                        Brand
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="item_brand"
                                        value={formState.item_brand}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                                <div className="input-block local-forms">
                                    <label>Categories <span className="login-danger">*</span>  </label>
                                    <select className="form-select form-control" name="item_domain"
                                        value={formState?.item_domain} onChange={handleChange}  >
                                        <option value="">Select Categories</option>
                                        {categories?.map((e) => (
                                            <option key={e._id} value={e._id}>
                                                {e.name}
                                            </option>
                                        ))}
                                    </select>
                                    {/* {orderError?.master_id && <div className="error">{orderError.master_id}</div>} */}
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-12">
                        <div className="input-block local-forms">
                            <label>
                                Remarks
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="remarks"
                                value={formState.remarks || ''}
                                onChange={handleChange}
                            />
                            <div className="error"></div>
                        </div>
                    </div>
                    <div className="col-12 text-end">
                        <button type="button" className="btn btn-secondary" onClick={handleModalClose}>Cancel</button>
                        <button type="button" className="btn btn-primary ms-2" onClick={() => saveItem(false)}>Save</button>
                        {mode !== 'edit' && (
                            <button type="button" className="btn btn-primary ms-2" onClick={() => saveItem(true)}>Add More</button>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    );
};

export default ItemModal;
