import React, { useEffect, useState } from 'react'
import Sidebar from '../Include/Sidebar'
import Header from '../Include/Header'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../Include/Footer';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getDrawing } from '../../../Store/Erp/Planner/Draw/Draw';
import { getUserPaintSystem } from '../../../Store/Store/PaintSystem/PaintSystem';
import axios from 'axios';
import { PLAN, V_URL } from '../../../BaseUrl';
import toast from 'react-hot-toast';
import { getUserInspectionSummary } from '../../../Store/Store/InspectionSummary/GetInspectionSummary';

const ManageDispatchNote = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state;
    const [dispatchNote, setDispatchNote] = useState({ drawNo: '', paint_system: '', dispatch_site: '', remarks: '', qty: '' });
    const [isObj, setIsObj] = useState({});
    const [paintObj, setPaintObj] = useState({});
    const [error, setError] = useState({});
    const [disable, setDisable] = useState(false);
    const [gridNoData, setGridNoData] = useState({ gridNo: '', ass_weight: '', sqm: '' });

    useEffect(() => {
        if (location.state) {
            setDispatchNote({
                drawNo: location.state.drawing_id?._id,
                paint_system: location.state.paint_system?._id,
                dispatch_site: location.state.dispatch_site,
                remarks: location.state.remarks,
                qty: location.state.qty
            });
            setGridNoData({ ...gridNoData, gridNo: location.state.grid_no })
        }
    }, [location.state]);

    useEffect(() => {
        dispatch(getDrawing());
        dispatch(getUserPaintSystem({ status: true }));
        dispatch(getUserInspectionSummary());
    }, [dispatch]);

    const drawData = useSelector((state) => state?.getDrawing?.user?.data);
    const paintSystemData = useSelector((state) => state?.getUserPaintSystem?.user?.data);
    const insSummaryData = useSelector(state => state.getUserInspectionSummary?.user?.data);

    useEffect(() => {
        const object = drawData?.find(drawing => drawing._id === dispatchNote.drawNo);
        setIsObj(object);
        const paintObject = paintSystemData?.find(paintSystem => paintSystem._id === dispatchNote.paint_system);
        setPaintObj(paintObject);
    }, [dispatchNote.drawNo, dispatchNote.paint_system, insSummaryData]);

    const handleChange = (e, name) => {
        const value = e.target ? e.target.value : e.value;
        setDispatchNote({ ...dispatchNote, [name]: value });
    }

    useEffect(() => {
        if (gridNoData.gridNo && isObj?.items) {
            const selectedGridNo = gridNoData.gridNo;
            const filteredItems = isObj.items.filter(
                item => item.grid_no === selectedGridNo
            );

            const totalAssemblyWeight = filteredItems.reduce(
                (sum, item) => sum + item.assembly_weight, 0
            );
            const totalAssemblySurfaceArea = filteredItems.reduce(
                (sum, item) => sum + item.assembly_surface_area, 0
            );

            setGridNoData(prevState => ({
                ...prevState,
                ass_weight: totalAssemblyWeight,
                sqm: totalAssemblySurfaceArea
            }));
        }
    }, [gridNoData.gridNo, isObj?.items]);

    const handleGridChange = (e) => {
        const selectedGridNo = e.target.value;
        setGridNoData(prevState => ({
            ...prevState,
            gridNo: selectedGridNo
        }));
    };

    const handleSubmit = () => {
        if (validation()) {
            setDisable(true);
            const myurl = `${V_URL}/user/manage-dispatch-note`;
            const formData = new URLSearchParams();
            formData.append('drawing_id', dispatchNote.drawNo);
            formData.append('prepared_by', localStorage.getItem('PAY_USER_ID'));
            formData.append('project', localStorage.getItem('PAY_USER_PROJECT_NAME'));
            formData.append('qty', dispatchNote.qty);
            formData.append('dispatch_site', dispatchNote.dispatch_site);
            formData.append('paint_system', dispatchNote.paint_system);
            formData.append('grid_no', gridNoData.gridNo);
            formData.append('remarks', dispatchNote.remarks);
            if (data?._id) {
                formData.append('id', data?._id);
            }
            axios({
                method: "post",
                url: myurl,
                data: formData,
                headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
            }).then((response) => {
                if (response.data?.success === true) {
                    toast.success(response.data.message);
                    navigate('/user/project-store/dispatch-note-management');
                } else {
                    toast.error(response.data.message);
                }
                setDisable(false);
            }).catch((error) => {
                console.log(error, "error");
                toast.error(error?.response?.data?.message);
                setDisable(false);
            })
        }
    }

    const handleReset = () => {
        setDispatchNote({ drawNo: '', paint_system: '', dispatch_site: '', remarks: '', qty: '' });
        setGridNoData({ gridNo: '', ass_weight: '', sqm: '' });
    }

    const validation = () => {
        var isValid = true;
        let err = {};
        if (!dispatchNote.drawNo) {
            isValid = false;
            err['drawing_no_err'] = 'Please select drawing no.'
        }
        if (!dispatchNote.paint_system) {
            isValid = false;
            err['paint_system_err'] = 'Please select paint system.'
        }
        if (!dispatchNote.dispatch_site) {
            isValid = false;
            err['dispatch_site_err'] = 'Please enter dispatch site.'
        }
        if (dispatchNote.qty <= 0) {
            isValid = false;
            err['qty_err'] = 'Please enter valid quantity.'
        }
        if (isNaN(dispatchNote.qty)) {
            isValid = false;
            err['qty_err'] = 'Quantity should be a number.'
        }
        if (dispatchNote.qty > isObj?.assembly_quantity) {
            isValid = false;
            err['qty_err'] = 'Quantity cannot be greater than the assembly quantity.';
        }
        setError(err);
        return isValid;
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const drawOptions = drawData
        ?.filter(drawing => insSummaryData?.some(summary => drawing?._id === summary?.drawing_id?._id) && drawing?.project?._id === localStorage.getItem('U_PROJECT_ID'))?.map(drawing => ({
            label: `${drawing.drawing_no} - ${drawing.rev} - ${drawing.assembly_no}`,
            value: drawing._id
        }));

    const paintSystemDataOptions = paintSystemData?.map(paintSystem => ({
        label: paintSystem.paint_system_no,
        value: paintSystem._id
    }));

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/project-store/dashboard">Dashboard </Link></li>
                                    <li className="breadcrumb-item"><i className="feather-chevron-right"></i></li>
                                    <li className="breadcrumb-item"><Link to="/user/project-store/dispatch-note-management">Dispatch Note For Painting</Link></li>
                                    <li className="breadcrumb-item"><i className="feather-chevron-right"></i></li>
                                    <li className="breadcrumb-item active">{data?._id ? 'Edit' : 'Add'} Dispatch Note</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="col-12">
                                            <div className="form-heading">
                                                <h4>{data?._id ? 'Edit' : 'Add'} Dispatch Note for Painting</h4>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-12 col-md-6 col-xl-6">
                                                <div className="input-block local-forms custom-select-wpr">
                                                    <label> Drawing No. - REV - Assembly No. <span className="login-danger">*</span></label>
                                                    <Dropdown
                                                        options={drawOptions}
                                                        value={dispatchNote.drawNo}
                                                        onChange={(e) => handleChange(e, 'drawNo')}
                                                        filter className='w-100'
                                                        placeholder="Select Drawing No."
                                                        disabled={data?._id}
                                                    />
                                                    <div className='error'>{error.drawing_no_err}</div>
                                                </div>
                                            </div>
                                        </div>

                                        {isObj && (
                                            <>
                                                <div className='row'>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>Client </label>
                                                            <input className='form-control' value={isObj?.project?.party?.name} readOnly />
                                                        </div>
                                                    </div>
                                                    {/* <div className='col-12 col-md-4 col-xl-4'>
                                                        <div className="input-block local-forms">
                                                            <label>Work Order / PO No.</label>
                                                            <input className='form-control' value={isObj?.project?.work_order_no} readOnly />
                                                        </div>
                                                    </div> */}
                                                    <div className='col-12 col-md-4 col-xl-4'>
                                                        <div className="input-block local-forms">
                                                            <label>Project</label>
                                                            <input className='form-control' value={isObj?.project?.name} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>REV </label>
                                                            <input className='form-control' value={isObj?.rev} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>Sheet No. </label>
                                                            <input className='form-control' value={isObj?.sheet_no} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>Assembly No. </label>
                                                            <input className='form-control' value={isObj?.assembly_no} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>Assembly Qty. </label>
                                                            <input className='form-control' value={isObj?.assembly_quantity} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>Grid Number </label>
                                                            <select className='form-control form-select' onChange={handleGridChange} value={gridNoData.gridNo}>
                                                                <option value="">Select Grid No.</option>
                                                                {isObj?.items
                                                                    ?.map((e) => e?.grid_no)
                                                                    .filter((value, index, self) => self.indexOf(value) === index)
                                                                    .map((gridNo) => (
                                                                        <option key={gridNo} value={gridNo}>
                                                                            {gridNo}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {gridNoData.gridNo && (
                                                        <>
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>Assembly weight </label>
                                                                    <input className='form-control' value={gridNoData.ass_weight} readOnly />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>Assembly surface area </label>
                                                                    <input className='form-control' value={gridNoData.sqm} readOnly />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>

                                                <div className="row">
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>Dispatch Site <span className="login-danger">*</span> </label>
                                                            <input className='form-control' name='dispatch_site' value={dispatchNote.dispatch_site} onChange={(e) => handleChange(e, 'dispatch_site')} />
                                                            <div className='error'>{error.dispatch_site_err}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms">
                                                            <label>QTY(NOS) <span className="login-danger">*</span> </label>
                                                            <input className='form-control' type="number" name='qty' value={dispatchNote.qty} onChange={(e) => handleChange(e, 'qty')} />
                                                            <div className='error'>{error.qty_err}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-xl-4">
                                                        <div className="input-block local-forms custom-select-wpr">
                                                            <label>Painting System <span className="login-danger">*</span></label>
                                                            <Dropdown
                                                                options={paintSystemDataOptions}
                                                                value={dispatchNote.paint_system}
                                                                onChange={(e) => handleChange(e, 'paint_system')}
                                                                filter className='w-100'
                                                                placeholder="Select Paint System."
                                                            // disabled={data?._id}
                                                            />
                                                            <div className="error">{error.paint_system_err}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {paintObj && (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>SURFACE PREPARATION STANDARD</label>
                                                                    <input className='form-control' value={paintObj?.surface_preparation} readOnly />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>PROFILE REQUIREMENTS</label>
                                                                    <input className='form-control' value={paintObj?.profile_requirement} readOnly />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>SALT TEST REQUIREMENTS</label>
                                                                    <input className='form-control' value={paintObj?.salt_test} readOnly />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>PAINT MANUFACTURER</label>
                                                                    <input className='form-control' value={paintObj?.paint_manufacturer?.name} readOnly />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>PRIMER PAINT/SHADE</label>
                                                                    <input className='form-control' value={paintObj?.prime_paint} readOnly />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>PRIMER APPLICATION METHOD</label>
                                                                    <input className='form-control' value={paintObj?.primer_app_method} readOnly />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>PRIMER DFT RANGE</label>
                                                                    <input className='form-control' value={paintObj?.primer_dft_range} readOnly />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>MIO PAINT/SHADE</label>
                                                                    <input className='form-control' value={paintObj?.mio_paint} readOnly />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>MIO DFT RANGE</label>
                                                                    <input className='form-control' value={paintObj?.mio_dft_range} readOnly />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>MIO APPLICATION METHOD</label>
                                                                    <input className='form-control' value={paintObj?.mio_app_method} readOnly />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>FINAL PAINT/SHADE</label>
                                                                    <input className='form-control' value={paintObj?.final_paint} readOnly />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>FINAL PAINT APPLICATION METHOD</label>
                                                                    <input className='form-control' value={paintObj?.final_paint_app_method} readOnly />
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>FINAL PAINT DFT RANGE</label>
                                                                    <input className='form-control' value={paintObj?.final_paint_dft_range} readOnly />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-xl-4">
                                                                <div className="input-block local-forms">
                                                                    <label>TOTAL DFT REQUIREMENT</label>
                                                                    <input className='form-control' value={paintObj?.total_dft_requirement} readOnly />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="input-block local-forms">
                                                            <label>Remarks</label>
                                                            <textarea className='form-control' name='remarks' value={dispatchNote.remarks} onChange={(e) => handleChange(e, 'remarks')}> </textarea>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="doctor-submit text-end">
                                                        {localStorage.getItem('ERP_ROLE') === PLAN ? (
                                                            <>
                                                                <button type="button" className="btn btn-primary submit-form me-2"
                                                                    onClick={handleSubmit} disabled={disable}>{disable ? "Processing..." : (data?._id ? 'Update' : 'Submit')}
                                                                </button>
                                                                <button type="button"
                                                                    className="btn btn-primary cancel-form" onClick={data?._id ? () => navigate('/user/project-store/dispatch-note-management') : handleReset}>{data?._id ? 'Cancel' : 'Reset'}</button>
                                                            </>
                                                        ) : (
                                                            <button type='button' className="btn btn-primary" onClick={() => navigate('/user/project-store/dispatch-note-management')}>
                                                                Back
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
        </div>
    )
}

export default ManageDispatchNote