import React, { useEffect, useMemo, useState } from 'react'
import Footer from '../Include/Footer';
import Header from '../Include/Header';
import Sidebar from '../Include/Sidebar';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDpr } from '../../../Store/Erp/Dpr/Dpr';
import { Pagination, Search } from '../Table';
import DropDown from '../../../Components/DropDown';
import Loader from '../Include/Loader';
import moment from 'moment';
import { PdfDownloadErp } from '../../../Components/ErpPdf/PdfDownloadErp';

const DPR = () => {

    const dispatch = useDispatch();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [limit, setlimit] = useState(10);
    const [disable, setDisable] = useState(true);

    useEffect(() => {
        if (disable === true) {
            dispatch(getDpr());
            setDisable(false);
        }
    }, [dispatch, disable]);

    const entity = useSelector((state) => state?.getDpr?.user?.data);

    const commentsData = useMemo(() => {
        let computedComments = entity;
        const projectId = localStorage.getItem('U_PROJECT_ID')

        if (projectId) {
            computedComments = computedComments?.filter((dpr) => dpr?.project === projectId)
        }

        if (search) {
            computedComments = computedComments.filter(
                (dpr) =>
                    dpr?.drawing_no?.toString()?.toLowerCase().includes(search.toLowerCase())
            );
        }
        setTotalItems(computedComments?.length);
        return computedComments?.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [currentPage, entity, search, limit]);

    const handleRefresh = () => {
        setSearch('');
        setDisable(true);
    }

    const handlePdfDownload = () => {
        const bodyFormData = new URLSearchParams();
        bodyFormData.append('drawingReport', JSON.stringify(commentsData));
        PdfDownloadErp({ apiMethod: 'post', url: 'download-daily-progress-report', body: bodyFormData });
    }


    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/project-store/dashboard">Dashboard </Link></li>
                                    <li className="breadcrumb-item"><i className="feather-chevron-right"></i></li>
                                    <li className="breadcrumb-item active">DPR List</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {disable === false ? (
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card card-table show-entire">
                                    <div className="card-body">
                                        <div className="page-table-header mb-2">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="doctor-table-blk">
                                                        <h3>DPR List</h3>
                                                        <div className="doctor-search-blk">
                                                            <div className="top-nav-search table-search-blk">
                                                                <form>
                                                                    <Search
                                                                        onSearch={(value) => {
                                                                            setSearch(value);
                                                                            setCurrentPage(1);
                                                                        }} />
                                                                    {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                                                    <a className="btn"><img src="/assets/img/icons/search-normal.svg"
                                                                        alt="search" /></a>
                                                                </form>
                                                            </div>
                                                            <div className="add-group">
                                                                <button type='button' onClick={handleRefresh}
                                                                    className="btn btn-primary doctor-refresh ms-2" data-toggle="tooltip" data-placement="top" title="Refresh"><img
                                                                        src="/assets/img/icons/re-fresh.svg" alt="refresh" /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pageDropDown col-auto text-end float-end ms-auto download-grp">
                                                    <div className='add-group'>
                                                        <button className='btn w-100 btn btn-primary doctor-refresh me-2 h-100' type='button' onClick={handlePdfDownload}>Download DPR <i className="fa-solid fa-download mx-2"></i></button>
                                                    </div>
                                                    <DropDown limit={limit} onLimitChange={(val) => setlimit(val)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table border-0 custom-table comman-table  mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Sr.</th>
                                                        <th>Draw No.</th>
                                                        <th>REV</th>
                                                        <th>Assm. No.</th>
                                                        {/* <th>Grid No.</th> */}
                                                        <th>Qty.</th>
                                                        <th>Assm. Wt.</th>
                                                        <th>Total Wt.</th>
                                                        <th>Issue Off. Dt.</th>
                                                        <th>Issue Acc. Dt.</th>
                                                        {/* <th>Issue Rej. Dt.</th> */}
                                                        <th>Fitup Off. Dt.</th>
                                                        <th>Fitup Acc. Dt.</th>
                                                        <th>Fitup Rej. Dt.</th>
                                                        <th>Weld Vis. Off. Dt.</th>
                                                        <th>Weld Vis. Acc. Dt.</th>
                                                        <th>Weld Vis. Rej. Dt.</th>
                                                        <th>Surface Off. Dt.</th>
                                                        <th>Surface Acc. Dt.</th>
                                                        <th>Surface Rej. Dt.</th>
                                                        <th>Mio Off. Dt.</th>
                                                        <th>Mio Acc. Dt.</th>
                                                        <th>Mio Rej. Dt.</th>
                                                        <th>Final Coat Off. Dt.</th>
                                                        <th>Final Coat Acc. Dt.</th>
                                                        <th>Final Coat Rej. Dt.</th>
                                                        <th>Dispatch Dt.</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {commentsData?.map((elem, i) =>
                                                        <tr key={i}>
                                                            <td>{(currentPage - 1) * limit + i + 1}</td>
                                                            <td>{elem.drawing_no}</td>
                                                            <td>{elem.rev}</td>
                                                            <td>{elem.assembly_no}</td>
                                                            {/* <td>{elem.grid_no || '-'}</td> */}
                                                            <td>{elem.assembly_quantity}</td>
                                                            <td>{elem.assembly_weight || 'N/A'}</td>
                                                            <td>{elem.total_item_weight || 'N/A'}</td>
                                                            <td>{elem.material_issue_date !== "" ? moment(elem.material_issue_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.material_acceptance_date !== "" ? moment(elem.material_acceptance_date).format('YYYY-MM-DD HH:mm') : '-   '}</td>
                                                            {/* <td>{elem.issue_rej_date !== "" ? moment(elem.issue_rej_date).format('YYYY-MM-DD HH:mm') : '-'}</td> */}
                                                            <td>{elem.fitup_issue_date !== "" ? moment(elem.fitup_issue_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.fitup_accepted_date !== "" ? moment(elem.fitup_accepted_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.fitup_rej_date !== "" ? moment(elem.fitup_rej_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.weld_visual_issue_date !== "" ? moment(elem.weld_visual_issue_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.weld_visual_accepted_date !== "" ? moment(elem.weld_visual_accepted_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.weld_visual_rej_date !== "" ? moment(elem.weld_visual_rej_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.surface_primer_offer_date !== "" ? moment(elem.surface_primer_offer_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.surface_primer_accepted_date !== "" ? moment(elem.surface_primer_accepted_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.surface_primer_rejected_date !== "" ? moment(elem.surface_primer_rejected_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.mio_offer_date !== "" ? moment(elem.mio_offer_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.mio_accepted_date !== "" ? moment(elem.mio_accepted_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.mio_rejected_date !== "" ? moment(elem.mio_rejected_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.final_coat_offer_date !== "" ? moment(elem.final_coat_offer_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.final_coat_accepted_date !== "" ? moment(elem.final_coat_accepted_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.final_coat_rejected_date !== "" ? moment(elem.final_coat_rejected_date).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                            <td>{elem.dispatchDate !== "" ? moment(elem.dispatchDate).format('YYYY-MM-DD HH:mm') : '-'}</td>
                                                        </tr>
                                                    )}
                                                    {commentsData?.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="999">
                                                                <div className="no-table-data">
                                                                    No Data Found!
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row align-center mt-3 mb-2">
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                                                <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                                                    aria-live="polite">Showing {Math.min(limit, totalItems)} from {totalItems} data</div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                    id="DataTables_Table_0_paginate">
                                                    <Pagination
                                                        total={totalItems}
                                                        itemsPerPage={limit}
                                                        currentPage={currentPage}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : <Loader />}

                </div>
                <Footer />
            </div>
        </div>
    )
}

export default DPR