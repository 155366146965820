import React, { useEffect, useState } from 'react'
import Header from '../../Include/Header'
import Sidebar from '../../Include/Sidebar'
import { Link, useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import OrderForm from '../../../../Components/Forms/OrderForm'
import ItemsTable from '../../../../Components/Table/ItemTable'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminItem } from '../../../../Store/Store/Item/AdminItem'
import { AddOrder } from '../../../../Store/Store/Order/ManageOrder'
import toast from 'react-hot-toast'
import Swal from 'sweetalert2'
import { M_STORE } from '../../../../BaseUrl'
import { getMainStock } from '../../../../Store/Store/Stock/getMainStock'
import { getGenMaster } from '../../../../Store/Store/GenralMaster/GenMaster'

const MenageOrder = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [editeMode, setEditeMode] = useState(false);
    const headers = {
        'Name': 'item_name',
        'Unit': 'unit',
        'M.code': 'm_code',
        "Brand": "item_brand",
        "Categories": "Categories_name",
        'Remarks': 'remarks',
    }
    const [disable, setDisable] = useState(true);
    const [filter, setFilter] = useState({
        date: {
            start: null,
            end: null
        }
    });
    const [error, setError] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [entity, setEntity] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [singleItem, setSingleItems] = useState({
        unit: '',
        gst: '',
        mcode: '',
        item_id: '',
        item_name: ''
    });
    const [singlecat, setSinglecate] = useState({
        cat_id: "",
        cat_name: ""
    });
    const [item, setItem] = useState({
        item_id: '',
        item_name: '',
        unit: '',
        m_code: '',
        item_brand: "",
        item_domain: '',
        Categories_name: '',
        b_quantity: 0,
        remarks: '',
    });

    const [calcItem, setCalCItem] = useState({
        item_id: '',
        quantity: 0,
        item_brand: "",
        Categories: '',
        Categories_name: '',
        b_quantity: 0,
        remarks: '',
    });

    const [formData, setFormData] = useState({
        order_date: '',
        bill_no: '',
        party_id: '',
        project_id: '',
        receiveBy: '',
        master_id: ''
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('PAY_USER_TOKEN') === null) {
            navigate("/user/login");
        } else if (localStorage.getItem('VI_PRO') !== `${M_STORE}`) {
            toast.error('Access Denied. You do not have permission to view this product. Please contact your administrator for assistance.')
            navigate("/user/login");
        }
        if (disable === true) {
            fetchData();
        }
    }, [navigate, disable, filter]);

    const fetchData = () => {
        const bodyFormData = new URLSearchParams();
        bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'))
        bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'))
        bodyFormData.append('filter', JSON.stringify(filter))
        dispatch(getMainStock(bodyFormData));
        setDisable(false);
    }
    useEffect(() => {
        if (modalOpen === true) {
            dispatch(getGenMaster({ tag_id: 17 }));
        } else {
            dispatch(getGenMaster({ tag_id: 15 }));
        }
    }, [modalOpen]);
    const stock = useSelector((state) => state.getMainStock?.user?.data || []);
    const itemDetails = useSelector((state) => state.getAdminItem?.user?.data || []);
    const categories = useSelector((state) => state.getGenMaster?.user?.data || []);

    useEffect(() => {
        dispatch(getAdminItem());
    }, [dispatch])

    useEffect(() => {
        if (itemDetails.length > 0) {
            setEntity(itemDetails);
        }
    }, [itemDetails]);

    // Just to populate the sinfle item details
    useEffect(() => {
        const filteredData = itemDetails?.find((it) => it?._id === calcItem.item_id);
        const categoriesData = categories?.find((it) => it?._id === calcItem.Categories);
        setSingleItems({
            item_id: filteredData?._id,
            item_name: filteredData?.name,
            unit: filteredData?.unit?.name,
            gst: filteredData?.gst_percentage,
            mcode: filteredData?.mcode
        })
        setSinglecate({
            cat_id: categoriesData?._id,
            cat_name: categoriesData?.name
        })
    }, [itemDetails, calcItem.item_id, calcItem.Categories])

    const calculation = (e) => {
        const { name, value } = e.target
        setCalCItem((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let itemName = calcItem.item_id
    let Amount = parseFloat(calcItem.quantity) * parseFloat(calcItem.rate);
    let disAmount = Amount * calcItem.discount / 100
    let spDiscount = (Amount - disAmount) * calcItem.sp_discount / 100;
    let taxableAmount = Amount - disAmount - spDiscount;
    let gstAmount = (taxableAmount * parseFloat(singleItem?.gst)) / 100;
    let total = taxableAmount + gstAmount;

    useEffect(() => {
        setItem({
            item_id: singleItem?.item_id,
            item_name: singleItem.item_name,
            quantity: calcItem.quantity,
            m_code: singleItem.mcode,
            unit: singleItem.unit,
            item_brand: calcItem?.item_brand,
            item_domain: singlecat?.cat_id,
            Categories_name: singlecat?.cat_name,
            remarks: calcItem?.remarks,
        })
    }, [itemName, calcItem?.remarks, calcItem?.Categories])

    useEffect(() => {
        const filteredData = stock?.store_items?.find((it) => it?.item_id === calcItem.item_id);
        setCalCItem((prev) => ({
            ...prev,
            b_quantity: filteredData?.balance || 0
        }))
    }, [stock, calcItem.item_id])

    const handleChange = (e) => {
        calculation(e);
        setError("")
    }
    const validation = () => {
        var isValid = true;
        let err = {};
        if (!item.item_id) {
            isValid = false;
            err['item_error'] = "Please select an item";
        }
        setError(err);
        return isValid;
    };
    const handleEdit = (index) => {
        setEditIndex(index)
        const itemToEdit = data[index]
        setEditeMode(true)
        setCalCItem(itemToEdit);
        setModalOpen(true);
    };

    const handleSave = () => {
        // if (validation()) {
        if (editeMode && editIndex !== null) {
            setData((prevData) => {
                const updatedData = [...prevData];
                updatedData[editIndex] = item;
                return updatedData;
            });
            setEditeMode(false);
            setEditIndex(null);
        } else {
            setData((prevData) => [...prevData, item]);
            setEditeMode(false)
        }
        handleModalClose();
        // }
    };
    const handleDelete = (index) => {
        const itemName = data[index]?.item_name || "this item";
        Swal.fire({
            title: `Are you sure you want to delete ${itemName}?`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
        }).then((result) => {
            if (result.isConfirmed) {
                setData((prevData) => prevData.filter((_, i) => i !== index));
            }
        });
    };

    const handleReset = () => {
        setItem({
            item_id: '',
            item_name: '',
            unit: '',
            m_code: '',
            quantity: 1,
            remarks: '',
        });
        setCalCItem({
            item_id: '',
            quantity: 0,
            rate: 0,
            item_brand: "",
            Categories: '',
            b_quantity: 0,
            remarks: '',
        });
        setError("")
    }
    const handleAddMore = () => {
        if (validation()) {
            setData((prevData) => {
                return [...prevData, item];
            });
            handleReset();
        }
    };
    const handleAddItem = () => {
        setEditeMode(false)
        setModalOpen(true)
    }
    const handleModalClose = () => {
        setModalOpen(false)
        setItem({
            item_id: '',
            unit: '',
            m_code: '',
            quantity: 0,
            remarks: '',
        });
        setCalCItem({
            item_id: '',
            quantity: 0,
            remarks: '',
        });
        setError("")
    };
    const handleSubmit = (addForm) => {
        if (!addForm.is_edit) {
            if (data?.length > 0) {
                const payload = {
                    "firm_id": localStorage.getItem('PAY_USER_FIRM_ID'),
                    "year_id": localStorage.getItem('PAY_USER_YEAR_ID'),
                    "order_date": addForm.order_date,
                    "bill_no": addForm.bill_no,
                    "party_id": addForm.party_id,
                    "project_id": addForm.project_id,
                    "master_id": addForm.master_id,
                    "lr_date": addForm?.lr_date,
                    "lr_no": addForm?.lr_no,
                    "items_details": data,
                    "transport_id": addForm?.transport_id,
                    "transport_date": addForm?.transport_date,
                    "vehical_no": addForm?.vehical_no,
                    "challan_no": addForm?.challan,
                    "receive_date": addForm?.receive_date,
                    "site_location": addForm?.site_location
                }
                dispatch(AddOrder(payload))
                    .then((res) => {
                        if (res.payload.success === true) {
                            navigate('/main-store/user/order-management')
                        }
                    }).catch((error) => {
                        console.log(error, 'ERROR');
                    })
            } else {
                toast.error('Please add the item details')
            }
        }
    }

    const handleBack = () => {
        if (data?.length === 0) {
            toast.error('Please add items')
        } else {
            navigate('/main-store/user/order-management ')
        }
    }
    return (
        <div className={`main-wrapper ${false ? "slide-nav" : ""}`}>
            <Header handleOpen={() => { }} />
            <Sidebar />
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/main-store/user/dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right"></i>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/main-store/user/order-management">
                                            Purchase Request
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right"></i>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        {"Add"} Purchase Request
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <OrderForm
                        title={'Purchase Request'}
                        dropdown_name={'Prepared Name'}
                        formData={formData}
                        tag_number={15}
                        isEdit={false}
                        handleSubmit={handleSubmit}
                    />
                    <ItemsTable
                        headers={headers}
                        data={data}
                        onAddItem={handleAddItem}
                        onDeleteItem={handleDelete}
                        onEditItem={handleEdit}
                    />
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col-12 text-end">
                                        <div className="doctor-submit text-end">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handleBack}
                                            >
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        show={modalOpen}
                        backdrop="static"
                        size="lg"
                        keyboard={false}
                        onHide={handleModalClose}
                    // handleClose= {handleModalClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Items Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="modal-container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="input-block local-forms">
                                                            <label>
                                                                Item Name
                                                                <span className="login-danger">*</span>
                                                            </label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="item_id"
                                                                value={calcItem.item_id}
                                                                onChange={handleChange}
                                                            >
                                                                <option value="">Select Item</option>
                                                                {entity.map((item) => <option key={item._id} value={item._id}>{item.name}</option>)}
                                                            </select>
                                                            <div className='error'>{error.item_error}</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block local-forms">
                                                            <label>
                                                                Unit
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                name="unit"
                                                                value={calcItem.item_id === '' ? "" : singleItem?.unit}
                                                                onChange={handleChange}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-block local-forms">
                                                            <label>M. Code</label>
                                                            <input
                                                                type='text'
                                                                className="form-control"
                                                                name="mcode"
                                                                value={calcItem.item_id === '' ? "" : singleItem?.mcode}
                                                                onChange={handleChange}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-block local-forms">
                                                            <label>
                                                                Stock Quantity
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="b_quantity"
                                                                value={calcItem.b_quantity}
                                                                onChange={handleChange}
                                                                disabled
                                                            />
                                                            {/* <div className='error'>{error.b_quantity}</div> */}
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="input-block local-forms">
                                                                <label>
                                                                    Require Quantity
                                                                    <span className="login-danger">*</span>
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="quantity"
                                                                    value={calcItem.quantity}
                                                                    onChange={handleChange}
                                                                />
                                                                <div className='error'>{error.quantity_err}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="input-block local-forms">
                                                                <label>
                                                                    Brand
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="item_brand"
                                                                    value={calcItem.item_brand}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6 ">
                                                            <div className="input-block local-forms">
                                                                <label>Categories <span className="login-danger">*</span>  </label>
                                                                <select className="form-select form-control" name="Categories"
                                                                    value={calcItem?.Categories} onChange={handleChange}  >
                                                                    <option value="">Select Categories</option>
                                                                    {categories?.map((e) => (
                                                                        <option key={e._id} value={e._id}>
                                                                            {e.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {/* {orderError?.master_id && <div className="error">{orderError.master_id}</div>} */}
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-6">
                                                            <div className="input-block local-forms">
                                                                <label>
                                                                    Categories
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="Categories"
                                                                    value={calcItem.Categories}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="input-block local-forms">
                                                            <label>
                                                                Remarks
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="remarks"
                                                                value={calcItem.remarks}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 text-end">
                                                    <div className="doctor-submit text-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary cancel-form"
                                                            onClick={handleModalClose}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary submit-form ms-2"
                                                            onClick={handleSave}
                                                        >
                                                            Save
                                                        </button>
                                                        {
                                                            editeMode ? '' : <button
                                                                type="button"
                                                                className="btn btn-primary submit-form ms-2"
                                                                onClick={handleAddMore}
                                                            >
                                                                Add More
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default MenageOrder