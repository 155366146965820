import React, { useEffect, useState } from 'react'
import Header from '../../Include/Header'
import Sidebar from '../../Include/Sidebar'
import { Link, useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import OrderForm from '../../../../Components/Forms/OrderForm'
import ItemsTable from '../../../../Components/Table/ItemTable'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminItem } from '../../../../Store/Store/Item/AdminItem'
import { addIssue } from '../../../../Store/Store/Issue/AddIssue'
import toast from 'react-hot-toast'
import Swal from 'sweetalert2'
import { M_STORE, V_URL } from '../../../../BaseUrl'
import { getOrder } from '../../../../Store/Store/Order/Order'
import { getOneIssue } from '../../../../Store/Store/Issue/GetOneIssue'
import moment from 'moment'
import { getGenMaster } from '../../../../Store/Store/GenralMaster/GenMaster'
import { getParty } from '../../../../Store/Store/Party/Party'
import { getProject } from '../../../../Store/Store/Project/Project'
import { getAdminTransport } from '../../../../Store/Store/StoreMaster/Transport/AdminTransport'
import axios from 'axios';

const ManageIssuePurchaseReturn = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [orderError, setOrderError] = useState({});
  const [machineData, setMachineData] = useState([]);
  const [disable, setDisable] = useState(false);

  const [editeMode, setEditeMode] = useState(false);
  const headers = {
    'Name': 'item_name',
    'Unit': 'unit',
    'M.code': 'm_code',
    'QTY': 'quantity',
    'Rate': 'rate',
    'Amount': 'amount',
    'Remarks': 'remarks',
    'Total amount': 'amount',
  }
  const [err, setError] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [entity, setEntity] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [selectedIssueNo, setSelectedIssueNo] = useState('');
  const [formData, setFormData] = useState({
    order_date: '',
    bill_no: '',
    party_id: '',
    project_id: '',
    receiveBy: '',
    master_id: '',
  });
  const [SingleItems, setSingleItems] = useState({
    unit: '',
    gst: '',
    mcode: '',
    item_id: '',
    item_name: '',
    order_date: '',
    voucher_no: '',
    bill_no: '',
    party_id: '',
    project_id: '',
    master_id: '',
    is_edit: 'false',
    isexternal: false,
    machine_code: '',
    transport_date: '',
    upload_pdf: '',
    transport_id: '',
    vehical_no: '',
    po_no: '',
    challan_no: '',
    receive_date: '',
    payment_date: '',
    payment_days: '',
    lr_no: '',
    lr_date: '',
    gate_pass_no: '',
    address: '',
    issue_no: '',
  });
console.log("SingleItems==>",SingleItems);

  const [item, setItem] = useState({
    item_id: '',
    item_name: '',
    unit: '',
    m_code: '',
    quantity: 1,
    rate: 0,
    amount: 0,
    discount: 0,
    discount_amount: 0,
    sp_discount: 0,
    sp_discount_amount: 0,
    taxable: 0,
    gst: 0,
    gst_amount: 0,
    total_amount: 0,
    remarks: '',
  });
  const [calcItem, setCalCItem] = useState({
    item_id: '',
    quantity: 0,
    rate: 0,
    amount: 0,
    discount: '',
    discount_amount: 0,
    sp_discount: '',
    sp_discount_amount: 0,
    gst: 0,
    gst_amount: 0,
    total_amount: 0,
    remarks: '',
  });
  const [filter, setFilter] = useState({
    date: {
      start: null,
      end: null
    }
  });
  const tag_number = 14;
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const itemDetails = useSelector((state) => state.getAdminItem?.user?.data || []);
  const getAllIssue = useSelector((state) => state?.getIssue?.user?.data) || [];

  const getOneIsseItem = useSelector((state) => state?.getOneIssue?.user?.data) || {};

  useEffect(() => {
    dispatch(getAdminItem());
  }, [dispatch])

  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    } else if (localStorage.getItem('VI_PRO') !== `${M_STORE}`) {
      toast.error('Access Denied. You do not have permission to view this product. Please contact your administrator for assistance.')
      navigate("/user/login");
    }
    fetchData();
  }, [navigate, disable, filter, search]);

  const fetchData = () => {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append("search", search);
    bodyFormData.append("filter", JSON.stringify(filter));
    bodyFormData.append("tag_number", 13);
    bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
    bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));
    dispatch(getOrder({ formData: bodyFormData }));
  };

  useEffect(() => {
    if (selectedIssueNo) {
      const issue = getAllIssue.find((issue) => issue.issue_no === selectedIssueNo);
      if (issue) {
        dispatch(getOneIssue({ id: issue._id, tag_number: 13 }));
      }
    }
  }, [selectedIssueNo, dispatch, getAllIssue]);

  // const handleIssueChange = (e) => {
  //   setSelectedIssueNo(e.target.value);
  //   if (selectedIssueNo) {
  //     const selectedIssue = getAllIssue.find((issue) => issue?.issue_no === selectedIssueNo);
  //     if (selectedIssue) {
  //       setSingleItems({
  //         order_date: moment(selectedIssue.order_date).format("YYYY-MM-DD") || "",
  //         voucher_no: selectedIssue.voucher_no || "",
  //         bill_no: selectedIssue.bill_no || "",
  //         party_id: selectedIssue.party?._id || "",
  //         project_id: selectedIssue.project?._id || "",
  //         master_id: selectedIssue.master?._id || "",
  //         is_edit: "true",
  //         isexternal: selectedIssue.isexternal || false,
  //         machine_code: selectedIssue.machine?._id || "",
  //         transport_date: moment(selectedIssue.transport_date).format("YYYY-MM-DD") || "",
  //         upload_pdf: selectedIssue.pdf || "",
  //         transport_id: selectedIssue.transport_details?._id || "",
  //         vehical_no: selectedIssue.vehical_no || "",
  //         po_no: selectedIssue.po_no || "",
  //         challan: selectedIssue.challan_no || "",
  //         receive_date: moment(selectedIssue.receive_date).format("YYYY-MM-DD") || "",
  //         payment_date: moment(selectedIssue.payment_date).format("YYYY-MM-DD") || "",
  //         payment_days: selectedIssue.payment_days || "",
  //         lr_no: selectedIssue.lr_no || "",
  //         gate_pass_no: selectedIssue.gate_pass_no || "",
  //         issue_no: selectedIssue.issue_no || "",
  //         lr_date: moment(selectedIssue.lr_date).format("YYYY-MM-DD") || "",
  //       });
  //     }
  //   }
  // };
  const handleIssueChange = (e) => {
    const selectedIssueNo = e.target.value;
    setSelectedIssueNo(selectedIssueNo);
    if (!selectedIssueNo || selectedIssueNo === "Select Issue No") {
      // Reset the form to blank
      setSingleItems({
        order_date: "",
        voucher_no: "",
        bill_no: "",
        party_id: "",
        project_id: "",
        master_id: "",
        is_edit: "true",
        isexternal: false,
        machine_code: "",
        transport_date: "",
        upload_pdf: "",
        transport_id: "",
        vehical_no: "",
        po_no: "",
        challan: "",
        receive_date: "",
        payment_date: "",
        payment_days: "",
        lr_no: "",
        gate_pass_no: "",
        issue_no: "",
        lr_date: "",
        address: "",
      });
      return;
    }

    if (selectedIssueNo) {
      const selectedIssue = getAllIssue.find((issue) => issue.issue_no === selectedIssueNo);
      if (selectedIssue) {
        setSingleItems({
          order_date: moment(selectedIssue.order_date).format("YYYY-MM-DD") || "",
          voucher_no: selectedIssue.voucher_no || "",
          bill_no: selectedIssue.bill_no || "",
          party_id: selectedIssue.party?._id || "",
          project_id: selectedIssue.project?._id || "",
          master_id: selectedIssue.master?._id || "",
          is_edit: "true",
          isexternal: selectedIssue.isexternal || false,
          machine_code: selectedIssue.machine?._id || "",
          transport_date: moment(selectedIssue.transport_date).format("YYYY-MM-DD") || "",
          upload_pdf: selectedIssue.pdf || "",
          transport_id: selectedIssue.transport_details?._id || "",
          vehical_no: selectedIssue.vehical_no || "",
          po_no: selectedIssue.po_no || "",
          challan_no: selectedIssue.challan_no || "",
          receive_date: moment(selectedIssue.receive_date).format("YYYY-MM-DD") || "",
          payment_date: moment(selectedIssue.payment_date).format("YYYY-MM-DD") || "",
          payment_days: selectedIssue.payment_days || "",
          lr_no: selectedIssue.lr_no || "",
          gate_pass_no: selectedIssue.gate_pass_no || "",
          issue_no: selectedIssue.issue_no || "",
          lr_date: moment(selectedIssue.lr_date).format("YYYY-MM-DD") || "",
          address: selectedIssue.address
        });
      } else {
        // Reset the form if no issue matches
        setSingleItems({
          unit: "",
          gst: "",
          mcode: "",
          item_id: "",
          item_name: "",
          order_date: "",
          voucher_no: "",
          bill_no: "",
          party_id: "",
          project_id: "",
          master_id: "",
          is_edit: "false",
          isexternal: false,
          machine_code: "",
          transport_date: "",
          upload_pdf: "",
          transport_id: "",
          vehical_no: "",
          po_no: "",
          challan_no: "",
          receive_date: "",
          payment_date: "",
          payment_days: "",
          lr_no: "",
          lr_date: "",
          gate_pass_no: "",
          issue_no: "",
        });
      }
    }
  };


  useEffect(() => {
    if (itemDetails.length > 0) {
      setEntity(itemDetails);
    }
  }, [itemDetails]);

  // Just to populate the sinfle item details
  useEffect(() => {
    const filteredData = itemDetails?.find((it) => it?._id === calcItem.item_id);
    setSingleItems({
      item_id: filteredData?._id,
      item_name: filteredData?.name,
      unit: filteredData?.unit?.name,
      gst: filteredData?.gst_percentage,
      mcode: filteredData?.mcode
    })
  }, [itemDetails, calcItem.item_id])

  const calculation = (e) => {
    const { name, value } = e.target
    setCalCItem((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  let itemName = calcItem.item_id
  let Amount = parseFloat(calcItem.quantity) * parseFloat(calcItem.rate);
  let disAmount = Amount * calcItem.discount / 100
  let spDiscount = (Amount - disAmount) * calcItem.sp_discount / 100;
  let taxableAmount = Amount - disAmount - spDiscount;
  let gstAmount = (taxableAmount * parseFloat(SingleItems?.gst)) / 100;
  let total = taxableAmount + gstAmount;

  useEffect(() => {
    setItem({
      item_id: SingleItems?.item_id,
      item_name: SingleItems.item_name,
      quantity: calcItem.quantity,
      m_code: SingleItems.mcode,
      unit: SingleItems.unit,
      rate: calcItem.rate,
      amount: Amount.toFixed(2) || 0,
      discount: 0,
      discount_amount: 0,
      sp_discount: 0,
      sp_discount_amount: 0,
      taxable_amount: 0,
      gst: 0,
      gst_amount: 0,
      total_amount: Amount.toFixed(2) || 0,
      remarks: calcItem?.remarks
    })
  }, [itemName, Amount, disAmount, spDiscount, taxableAmount, gstAmount, total, calcItem?.remarks])

  const validationModal = () => {
    let isValid = true;
    let err = {};

    if (!item.item_id) {
      isValid = false;
      err['item_error'] = "Please select an item";
    }

    if (!item.quantity || item.quantity === "") {
      isValid = false;
      err['quantity_err'] = "Please select a quantity";
    } else if (parseFloat(item.quantity) < 0 || parseFloat(item.quantity) === 0) {
      isValid = false;
      err['quantity_err'] = "Quantity value cannot be negative or equal to zero";
    }

    if (!item.rate) {
      isValid = false;
      err["rate_err"] = "Please enter a valid rate greater than 0";
    }
    setError(err);
    return isValid;
  };
  const handleEdit = (index) => {
    setEditIndex(index)
    const itemToEdit = data[index]
    setEditeMode(true)
    setCalCItem(itemToEdit);
    setModalOpen(true);
  };
  const handleSave = () => {
    if (validationModal()) {
      if (editeMode && editIndex !== null) {
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[editIndex] = item;
          return updatedData;
        });
        setEditeMode(false);
        setEditIndex(null);
      } else {
        setData((prevData) => [...prevData, item]);
        setEditeMode(false)
      }
      handleModalClose();
    }
  };
  const handleDelete = (index) => {
    const itemName = data[index]?.item_name || "this item";
    Swal.fire({
      title: `Are you sure you want to delete ${itemName}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        setData((prevData) => prevData.filter((_, i) => i !== index));
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  const handleReset = () => {
    setItem({
      item_id: '',
      item_name: '',
      unit: '',
      m_code: '',
      quantity: 1,
      rate: 0,
      amount: 0,
      discount: 0,
      discount_amount: 0,
      sp_discount: 0,
      sp_discount_amount: 0,
      taxable_amount: 0,
      gst: 0,
      gst_amount: 0,
      total_amount: 0,
      remarks: '',
    });
    setCalCItem({
      item_id: '',
      quantity: 0,
      rate: 0,
      amount: 0,
      discount: '',
      discount_amount: 0,
      sp_discount: '',
      sp_discount_amount: 0,
      gst: 0,
      gst_amount: 0,
      total_amount: 0,
      remarks: '',
    });
    setError("")
  }
  const handleAddMore = () => {
    if (validationModal()) {
      setData((prevData) => {
        return [...prevData, item];
      });
      handleReset();
    }
  };
  const handleAddItem = () => {
    setEditeMode(false)
    setModalOpen(true)
  }
  const handleModalClose = () => {
    setModalOpen(false)
    setItem({
      item_id: '',
      unit: '',
      m_code: '',
      quantity: 0,
      rate: 0,
      amount: 0,
      discount: 0,
      discount_amount: 0,
      sp_discount: 0,
      sp_discount_amount: 0,
      taxtable: 0,
      gst: 0,
      gst_amount: 0,
      total_amount: 0,
      remarks: '',
    });
    setCalCItem({
      item_id: '',
      quantity: 0,
      rate: 0,
      amount: 0,
      discount: '',
      discount_amount: 0,
      sp_discount: '',
      sp_discount_amount: 0,
      gst: 0,
      gst_amount: 0,
      total_amount: 0,
      remarks: '',
    });
    setError("")
  };
  const handleSubmit = (SingleItems) => {

    if (!SingleItems.is_edit) {
      if (data?.length > 0) {
        const payload = {
          "firm_id": localStorage.getItem('PAY_USER_FIRM_ID'),
          "year_id": localStorage.getItem('PAY_USER_YEAR_ID'),
          "order_date": SingleItems.order_date,
          "bill_no": SingleItems.bill_no,
          "party_id": SingleItems.party_id,
          "machine_code": "66ab6e37ab979f403264e06d",
          "project_id": SingleItems.project_id,
          "master_id": SingleItems.master_id,
          "items_details": data,
          "machine_code": SingleItems.machine_code || null,
          "isexternal": SingleItems.isexternal,
          "transport_id": SingleItems?.transport_id,
          "receive_date": SingleItems.receive_date,
          "challan_no": SingleItems.challan_no,
          "vehical_no": SingleItems.vehical_no,
          "transport_date": SingleItems?.transport_date,
          "lr_date": SingleItems?.lr_date,
          "lr_no": SingleItems?.lr_no
        };
        dispatch(addIssue(payload))
          .then((res) => {
            if (res.payload.success === true) {
              navigate('/main-store/user/issue-purchase-return-management')
            }
          }).catch((error) => {
            console.log(error, 'ERROR');
          })
      } else {
        toast.error('Please add the item details')
      }
    }
  };
  console.log("SingleItems++", SingleItems);
  console.log("orderError++", orderError);

  useEffect(() => {
    dispatch(getGenMaster({ tag_id: 13 }));
    dispatch(getParty({ storeType: '1' }));
    dispatch(getProject())
    dispatch(getAdminTransport())
    getMachineCode()
  }, []);

  useEffect(() => {
    if (SingleItems.is_edit === 'false') {
      setSingleItems({
        id: formData?._id,
        order_date: moment(formData.order_date).format('YYYY-MM-DD') || '',
        voucher_no: formData?.voucher_no,
        bill_no: formData?.bill_no,
        party_id: formData?.party?._id,
        project_id: formData?.project?._id,
        master_id: formData?.master?._id,
        is_edit: SingleItems.is_edit,
        isexternal: formData?.isexternal,
        machine_code: formData?.machine?._id,
        transport_date: moment(formData?.transport_date).format('YYYY-MM-DD') || '',
        upload_pdf: formData?.pdf,
        transport_id: formData?.transport_details?._id,
        vehical_no: formData?.vehical_no,
        po_no: formData?.po_no,
        challan_no: formData?.challan_no,
        receive_date: moment(formData?.receive_date).format('YYYY-MM-DD') || '',
        payment_date: moment(formData?.payment_date).format('YYYY-MM-DD') || '',
        payment_days: formData?.payment_days,
        lr_no: formData?.lr_no,
        gate_pass_no: formData?.gate_pass_no,
        issue_no: formData?.issue_no,
        lr_date: moment(formData?.lr_date).format('YYYY-MM-DD') || '',
        address: formData?.address || '',
      });
    }
  }, [SingleItems.is_edit, formData]);

  const parties = useSelector((state) => state.getParty?.user?.data || []);
  const projects = useSelector((state) => state.getProject?.user?.data || []);
  const reciever = useSelector((state) => state.getGenMaster?.user?.data || []);
  const transport = useSelector((state) => state.getAdminTransport?.user?.data || []);

  const getMachineCode = () => {
    axios({
      method: "get",
      url: `${V_URL}/user/get-master`,
      params: { tag_id: 16 },
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
    }).then((res) => {
      if (res.data.success === true) {
        setMachineData(res.data.data);
      }
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSingleItems({ ...SingleItems, [name]: value });
    setOrderError((prevErrors) => ({ ...prevErrors, [name]: '' }));
    calculation(e);
    setError("")
  };

  useEffect(() => {
    if (SingleItems.order_date && SingleItems.payment_date) {
      const orderDate = moment(SingleItems.order_date);
      const paymentDate = moment(SingleItems.payment_date);
      const diffDays = paymentDate.diff(orderDate, 'days');
      setSingleItems((prevItems) => ({ ...prevItems, payment_days: diffDays }));
    }
  }, [SingleItems.order_date, SingleItems.payment_date]);

  const Ordervalidation = () => {
    let isValid = true;
    let err = {};
    const today = new Date().toISOString().split("T")[0];

    if (!SingleItems.order_date) {
      isValid = false;
      err['order_date'] = "Please select a date";
    } else if (SingleItems.order_date > today) {
      isValid = false;
      err['order_date'] = "Invalid Order Date";
    }

    if (tag_number !== 13 && !SingleItems.bill_no) {
      isValid = false;
      err['bill_no'] = "Please enter the bill number";
    }

    if (tag_number !== 13 && !SingleItems.challan_no) {
      isValid = false;
      err['challan_no'] = "Please enter the challan_no";
    }

    if (tag_number !== 13 && !SingleItems.receive_date) {
      isValid = false;
      err['receive_date'] = "Please select a date";
    } else if (tag_number !== 13 && SingleItems.receive_date > today) {
      isValid = false;
      err['receive_date'] = "Invalid Receive Date";
    }
    if (tag_number !== 13 && SingleItems.isexternal === true && !SingleItems.party_id) {
      isValid = false;
      err['party_id'] = "Please select a party";
    }

    if (!SingleItems.transport_id) {
      isValid = false;
      err['transport_id'] = "Please select a transport";
    }

    if ((tag_number === 13 || tag_number === 14) && !SingleItems.machine_code && !SingleItems.isexternal) {
      isValid = false;
      err['machine_code_err'] = "Please select a machine code";
    }
    if (!SingleItems.project_id) {
      isValid = false;
      err['project_id'] = "Please select a project";
    }

    if (!SingleItems.master_id) {
      isValid = false;
      err['master_id'] = `Please select Receiver Name`;
    }
    if (tag_number === 11) {
      if (!SingleItems.upload_pdf) {
        isValid = false;
        err['upload_pdf'] = "Please upload a PDF";
      }
      if (!SingleItems.payment_date) {
        isValid = false;
        err['payment_date'] = "Please upload a PDF";
      }
    }
    if (tag_number !== 13 && !SingleItems.lr_no) {
      isValid = false;
      err['lr_no'] = `Please select lr no`;
    }
    if (!SingleItems.issue_no) {
      isValid = false;
      err['issue_no'] = `Please select issue no`;
    }
    if (tag_number !== 13 && !SingleItems.lr_date) {
      isValid = false;
      err['lr_date'] = "Please select a date";
    } else if (tag_number !== 13 && SingleItems.lr_date > today) {
      isValid = false;
      err['lr_date'] = "Invalid Order Date";
    }
    setOrderError(err);
    return isValid;
  };

  const handlePdf = (e) => {
    if (e?.target?.files[0]) {
      const allowedTypes = ["application/pdf"];
      const fileType = e.target.files[0].type;
      if (allowedTypes.includes(fileType)) {
        setDisable(true);
        const myurl = `${V_URL}/upload-image`;
        var bodyFormData = new FormData();
        bodyFormData.append('image', e?.target?.files[0]);
        axios({
          method: "post",
          url: myurl,
          data: bodyFormData,
        }).then((response) => {
          if (response.data.success === true) {
            const data = response?.data?.data?.pdf;
            setSingleItems({ ...SingleItems, upload_pdf: data });
          }
          setDisable(false);
        }).catch((error) => {
          console.log(error, '!!');
          toast.error(error.response?.data?.message)
          setDisable(false);
        })
      } else {
        toast.error("Invalid file type. Only PDFs are allowed.");
      }
    }
  }

  const handleFormSubmit = () => {
    if (Ordervalidation()) {
      setDisable(true);
      if (tag_number === 13) {
        if (SingleItems.isexternal) {
          SingleItems.machine_code = null;
        } else {
          SingleItems.party_id = null;
        }
      }
      handleSubmit(SingleItems);
    }
  };

  return (
    <div className={`main-wrapper ${false ? "slide-nav" : ""}`}>
      <Header handleOpen={() => { }} />
      <Sidebar />

      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/main-store/user/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right"></i>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/main-store/user/issue-purchase-return-management">
                      Issue Purchase Return
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right"></i>
                  </li>
                  <li className="breadcrumb-item active">
                    {"Add"} Issue Purchase Return
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="col-12 d-flex justify-content-between">
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="input-block local-forms">
                        <label>
                          Issue No. <span className="login-danger">*</span>
                        </label>
                        <select
                          className="form-select form-control"
                          name="issue_no"
                          value={selectedIssueNo}
                          onChange={handleIssueChange}
                        >
                          <option value="">Select Issue No</option>
                          {getAllIssue.map((issue, index) => (
                            <option key={index} value={issue.issue_no}>
                              {issue.issue_no}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className={selectedIssueNo ? "col-12 col-md-4 col-xl-4 d-block" : "d-none"}>
                      <div className="input-block local-forms">
                        <label>{(tag_number === 11 || tag_number === 12 || tag_number === 15) ? 'Order' : 'Issue'} Date <span className="login-danger">*</span></label>
                        <input className="form-control" type="date" name="order_date"
                          value={SingleItems.order_date} onChange={handleChange} />
                        <div className='error'>{orderError?.order_date}</div>
                      </div>
                    </div>
                  </div>
                  <div className={selectedIssueNo ? "row" : "d-none"}>
                    <div className='row'>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="input-block local-forms">
                          <label>Bill No.{(tag_number !== 13) && (<span className="login-danger">*</span>)}</label>
                          <input
                            className="form-control"
                            type="text"
                            name="bill_no"
                            value={SingleItems?.bill_no}
                            onChange={handleChange}
                          />
                          {(tag_number !== 13) && (
                            orderError?.bill_no && <div className="error">{orderError.bill_no}</div>
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="input-block local-forms">
                          <label> Project Name <span className="login-danger">*</span> </label>
                          <select className="form-select form-control" name="project_id"
                            value={SingleItems?.project_id} onChange={handleChange}>
                            <option value="">Select Project</option>
                            {projects?.map((project) => (
                              <option key={project._id} value={project._id}>
                                {project.name}
                              </option>
                            ))}
                          </select>
                          {orderError?.project_id && <div className="error">{orderError.project_id}</div>}
                        </div>
                      </div>

                      {(tag_number === 13 || tag_number === 14) && (
                        <>
                          <div className="col-12 col-md-4 col-xl-4">
                            <div className="input-block local-forms">
                              <label> Issue Type <span className="login-danger">*</span> </label>
                              <select
                                className="form-select form-control"
                                name="isexternal"
                                onChange={(e) => handleChange({
                                  target: {
                                    name: e.target.name,
                                    value: e.target.value === "true"
                                  }
                                })}
                                value={SingleItems.isexternal}
                              >
                                <option value="false">Internal</option>
                                <option value="true">Third Party</option>
                              </select>
                            </div>
                          </div>

                          {!SingleItems.isexternal && (
                            <div className="col-12 col-md-4 col-xl-4">
                              <div className="input-block local-forms">
                                <label> Machine Code <span className="login-danger">*</span> </label>
                                <select className="form-select form-control" name="machine_code"
                                  value={SingleItems?.machine_code} onChange={handleChange}>
                                  <option value="">Select General Master</option>
                                  {machineData?.map((e) => (
                                    <option key={e.id} value={e._id}>
                                      {e.name}
                                    </option>
                                  ))}
                                </select>
                                {orderError?.machine_code_err && <div className="error">{orderError.machine_code_err}</div>}
                              </div>
                            </div>
                          )}
                          {SingleItems.isexternal === true && (
                            <div className="col-12 col-md-4 col-xl-4">
                              <div className="input-block local-forms">
                                <label> Address <span className="login-danger">*</span> </label>
                                <input className="form-control" type="text" name="address"
                                  value={SingleItems?.address} onChange={handleChange} />
                                {orderError?.address && <div className="error">{orderError.address}</div>}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>


                    {((tag_number === 13 || tag_number === 14) && SingleItems.isexternal === true) ? (
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="input-block local-forms">
                          <label>
                            Party Name {(tag_number !== 13) && (<span className="login-danger">*</span>)}</label>
                          <select
                            className="form-select form-control"
                            name="party_id"
                            value={SingleItems?.party_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Party</option>
                            {parties?.map((party) => (
                              <option key={party.id} value={party._id}>
                                {party.name}
                              </option>
                            ))}
                          </select>
                          {(tag_number !== 13) && (
                            orderError?.party_id && <div className="error">{orderError.party_id}</div>
                          )}
                        </div>
                      </div>
                    ) : (tag_number !== 13 || tag_number !== 14) && (<>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="input-block local-forms">
                          <label>
                            Party Name {(tag_number !== 13) && (<span className="login-danger">*</span>)}</label>
                          <select
                            className="form-select form-control"
                            name="party_id"
                            value={SingleItems?.party_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Party</option>
                            {parties?.map((party) => (
                              <option key={party._id} value={party._id}>
                                {party.name}
                              </option>
                            ))}
                          </select>
                          {(tag_number !== 13) && (
                            orderError?.party_id && <div className="error">{orderError.party_id}</div>
                          )}
                        </div>
                      </div>
                    </>
                    )}

                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="input-block local-forms">
                        <label>Receiver Name {(tag_number !== 13 && SingleItems.isexternal === true) && (<span className="login-danger">*</span>)}</label>
                        <select className="form-select form-control" name="master_id"
                          value={SingleItems?.master_id} onChange={handleChange}  >
                          <option value="">Select Receiver Name</option>
                          {reciever?.map((e) => (
                            <option key={e._id} value={e._id}>
                              {e.name}
                            </option>
                          ))}
                        </select>
                        {(tag_number !== 13 && SingleItems.isexternal === true) && (
                          orderError?.master_id && <div className="error">{orderError.master_id}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="input-block local-forms">
                        <label>
                          Receive Date {(tag_number !== 13) && (<span className="login-danger">*</span>)}</label>
                        <input
                          className="form-control"
                          type="date"
                          name="receive_date"
                          value={SingleItems.receive_date}
                          onChange={handleChange}
                        />
                        {(tag_number !== 13) && (
                          orderError?.receive_date && <div className="error">{orderError.receive_date}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="input-block local-forms">
                        <label>Transport <span className="login-danger">*</span></label>
                        <select className="form-select form-control" name="transport_id"
                          value={SingleItems?.transport_id} onChange={handleChange}>
                          <option value="">Select Transport</option>
                          {transport?.map((transport) => (
                            <option key={transport._id} value={transport._id}>
                              {transport.name}
                            </option>
                          ))}
                        </select>
                        {orderError?.transport_id && <div className="error">{orderError.transport_id}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="input-block local-forms">
                        <label> Transport Date</label>
                        <input className="form-control" type="date" name="transport_date"
                          value={SingleItems.transport_date} onChange={handleChange} />
                        <div className='error'>{orderError?.transportDate}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="input-block local-forms">
                        <label>  Vehicle </label>
                        <input className="form-control" type="text" name="vehical_no"
                          value={SingleItems.vehical_no} onChange={handleChange}
                        />
                        <div className='error'>{orderError?.vehical_no}</div>
                      </div>
                    </div>

                    {(tag_number === 11 || tag_number === 12) && (
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="input-block local-forms">
                          <label>PO No. </label>
                          <input className="form-control" type="text"
                            name="po_no" value={SingleItems.po_no} onChange={handleChange} />
                          <div className='error'>{orderError?.po_no}</div>
                        </div>
                      </div>
                    )}

                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="input-block local-forms">
                        <label> Challan {(tag_number !== 13) && (<span className="login-danger">*</span>)}</label>
                        <input className="form-control" type="text" name="challan_no"
                          value={SingleItems?.challan_no} onChange={handleChange} />
                        {(tag_number !== 13) && (
                          orderError?.challan_no && <div className="error">{orderError.challan_no}</div>
                        )}
                      </div>
                    </div>

                    {(tag_number === 11) && (
                      <>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-top-form">
                            <label className="local-top">PDF <span className="login-danger">*</span></label>
                            <div className="settings-btn upload-files-avator">
                              <label htmlFor="pdfFile" className="upload">Choose PDF File(s)</label>
                              <input type="file" id="pdfFile" onChange={handlePdf} accept=".pdf" className="hide-input" />
                            </div>
                            {SingleItems.upload_pdf ? (
                              <a href={SingleItems.upload_pdf} target='_blank'>
                                <img src='/assets/img/pdflogo.png' />
                              </a>
                            ) : null}
                            {orderError?.challan && <div className="error">{orderError.upload_pdf}</div>}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label> Payment Date <span className="login-danger">*</span></label>
                            <input className="form-control" type="date" name="payment_date"
                              value={SingleItems?.payment_date} onChange={handleChange} />
                            {orderError?.payment_date && <div className="error">{orderError.payment_date}</div>}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label> Payment Days</label>
                            <input className="form-control" value={SingleItems.payment_days} onChange={handleChange} readOnly />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="input-block local-forms">
                        <label> LR No {(tag_number !== 13) && (<span className="login-danger">*</span>)}</label>
                        <input className="form-control" value={SingleItems.lr_no} name='lr_no' onChange={handleChange} />
                        {(tag_number !== 13) && (
                          orderError?.lr_no && <div className="error">{orderError.lr_no}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="input-block local-forms">
                        <label> LR Date {(tag_number !== 13) && (<span className="login-danger">*</span>)}</label>
                        <input className="form-control" type="date" name="lr_date"
                          value={SingleItems?.lr_date} onChange={handleChange} />
                        {(tag_number !== 13) && (
                          orderError?.lr_date && <div className="error">{orderError.lr_date}</div>
                        )}
                      </div>
                    </div>
                    {tag_number === 13 && (
                      <>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label> Gate Pass No </label>
                            <input className="form-control" value={SingleItems?.gate_pass_no} name='gate_pass_no' onChange={handleChange} />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label> Issue No <span className="login-danger">*</span></label>
                            <input className="form-control" value={SingleItems?.issue_no} name='issue_no' onChange={handleChange} />
                            {(
                              orderError?.issue_no && <div className="error">{orderError.issue_no}</div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* <div className="col-12">
                    <div className="doctor-submit text-end">
                      <button type="button" className="btn btn-primary submit-form me-2" onClick={handleFormSubmit}> {SingleItems?.is_edit ? 'Save' : 'Submit'}</button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* <OrderForm title={'Issue Return'}
            dropdown_name={'Receiver Name'}
            isEdit={false}
            tag_number={14}
            handleSubmit={handleSubmit} /> */}

          <ItemsTable
            headers={headers}
            data={selectedIssueNo ? getOneIsseItem?.items_details : []}
            onAddItem={handleAddItem}
            onDeleteItem={handleDelete}
            onEditItem={handleEdit} />

          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="col-12 text-end">
                    <div className="doctor-submit text-end">
                      <button
                        type="button"
                        className="btn btn-primary mx-2"
                        onClick={() => navigate('/main-store/user/issue-purchase-return-management')}
                      >
                        Back
                      </button>
                      <button type="button" className="btn btn-primary submit-form me-2" onClick={handleFormSubmit}> {SingleItems?.is_edit ? 'Save' : 'Submit'}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={modalOpen}
            backdrop="static"
            size="lg"
            keyboard={false}
            onHide={handleModalClose}

          // handleClose= {handleModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Items Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="input-block local-forms">
                                      <label>
                                        Item Name
                                        <span className="login-danger">*</span>
                                      </label>
                                      <select
                                        className="form-select form-control"
                                        name="item_id"
                                        value={calcItem.item_id}
                                        onChange={handleChange}
                                      >
                                        <option value="">Select Item</option>
                                        {entity.map((item) => <option key={item._id} value={item._id}>{item.name}</option>)}
                                      </select>
                                      <div className="error">{err.item_error}</div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-block local-forms">
                                      <label>
                                        Unit
                                        {/* <span className="login-danger">*</span> */}
                                      </label>
                                      <input
                                        className="form-control"
                                        name="unit"
                                        value={calcItem.item_id === '' ? "" : SingleItems?.unit}
                                        onChange={handleChange}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-block local-forms">
                                      <label>M. Code</label>
                                      <input
                                        type='text'
                                        className="form-control"
                                        name="mcode"
                                        value={calcItem.item_id === '' ? "" : SingleItems?.mcode}
                                        onChange={handleChange}
                                        disabled
                                      />
                                      <div className="error">{err.m_code_err}</div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-block local-forms">
                                      <label>
                                        Quantity
                                        <span className="login-danger">*</span>
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="quantity"
                                        value={calcItem.quantity}
                                        onChange={handleChange}
                                      />
                                      <div className="error">{err.quantity_err}</div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-block local-forms">
                                      <label>
                                        Rate <span className="login-danger">*</span>
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="rate"
                                        value={calcItem.rate}
                                        onChange={handleChange}
                                      />
                                      <div className="error">{err.rate_err}</div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="input-block local-forms">
                                      <label>
                                        Amount
                                        {/* <span className="login-danger">*</span> */}
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="amount"
                                        value={item.amount}
                                        disabled
                                      />
                                      {/* <div className="error">{err.amount_err}</div> */}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="input-block local-forms">
                                      <label>
                                        Remarks
                                        {/* <span className="login-danger">*</span> */}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="remarks"
                                        value={calcItem.remarks}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 text-end">
                                  <div className="doctor-submit text-end">
                                    <button
                                      type="button"
                                      className="btn btn-primary cancel-form"
                                      onClick={handleModalClose}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary submit-form ms-2"
                                      onClick={handleSave}
                                    >
                                      Save
                                    </button>
                                    {
                                      editeMode ? '' : <button
                                        type="button"
                                        className="btn btn-primary submit-form ms-2"
                                        onClick={handleAddMore}
                                      >
                                        Add More
                                      </button>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>

  )
}

export default ManageIssuePurchaseReturn