import React, { useEffect, useState } from 'react'
import Header from '../../Include/Header'
import Sidebar from '../../Include/Sidebar'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminItem } from '../../../../Store/Store/Item/AdminItem'
import toast from 'react-hot-toast'
import { M_STORE, V_URL } from '../../../../BaseUrl'
import { getOrder } from '../../../../Store/Store/Order/Order'
import { Dropdown } from 'primereact/dropdown';
import { getSingleOrder } from '../../../../Store/Store/Order/GetSingleOrder'
import moment from 'moment'
import { getProject } from '../../../../Store/Store/Project/Project'
import { getGenMaster } from '../../../../Store/Store/GenralMaster/GenMaster'
import { getParty } from '../../../../Store/Store/Party/Party'
import { getAdminTransport } from '../../../../Store/Store/StoreMaster/Transport/AdminTransport'
import axios from 'axios'
import Return_Table from './Model_Componet/Return_Table'

const ManagePurchaseReturn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [formdata, setformDate] = useState({
    order_date: "",
    project_id: "",
    party_id: "",
    master_id: '',
    receive_date: '',
    transport_id: '',
    transport_date: "",
    vehical_no: '',
    po_no: "",
    upload_pdf: "",
    payment_date: "",
    payment_days: "",
    lr_no: "",
    lr_date: "",
  });
  const headers = {
    'Name': 'item_name',
    'Unit': 'unit',
    'M.code': 'm_code',
    'QTY': 'quantity',
    'Rate': 'rate',
    'Amount': 'amount',
    'Discount': 'discount',
    'Dis.Amt.': 'discount_amount',
    'SP.DSC.': 'sp_discount',
    'SP.DS.Amt.': 'sp_discount_amount',
    'Tax.Amt.': 'taxable_amount',
    'GST': 'gst',
    'GST Amount': 'gst_amount',
    'Total Amt.': 'total_amount',
    'Remarks': 'remarks',
  }

  const [disable, setDisable] = useState(true);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    date: {
      start: null,
      end: null
    }
  });
  const parties = useSelector((state) => state.getParty?.user?.data || []);
  const projects = useSelector((state) => state.getProject?.user?.data || []);
  const reciever = useSelector((state) => state.getGenMaster?.user?.data || []);
  const transport = useSelector((state) => state.getAdminTransport?.user?.data || []);
  const Purchase = useSelector((state => state?.getPurchaseorder?.user?.data))
  const getSinglePurchase = useSelector((state) => state?.getSinglePurchaseorder?.user?.data || [])

  const [billNo, setBillNo] = useState(null);
  const [challanNo, setChallanNo] = useState(null);
  const [Challanoption, setChallanoption] = useState(
    Purchase?.map(it => ({
      label: it.challan_no,
      value: it.challan_no
    })) || []
  );

  useEffect(() => {
    if (Purchase) {
      setChallanoption(
        Purchase.map(it => ({
          label: it.challan_no,
          value: it.challan_no
        }))
      );
    }
  }, [Purchase]);


  const getUniqueBillNumbers = () => {
    const uniqueBills = [];
    const billSet = new Set();

    Purchase?.forEach((purchase) => {
      if (purchase?.bill_no && !billSet.has(purchase.bill_no)) {
        billSet.add(purchase.bill_no);
        uniqueBills.push({
          label: purchase.bill_no,
          value: purchase.bill_no
        });
      }
    });
    return uniqueBills;
  };

  const handleBillNoChange = (e) => {
    setBillNo(null)
    const findBillno = Purchase?.find(
      (it) => it?.bill_no === e.value
    );
    const allBill = Purchase?.filter((it) => it?.bill_no === e.value)
    if (findBillno) {
      setChallanNo(null)
      setBillNo(findBillno?.bill_no || '');
      setChallanoption(
        allBill.map(it => ({
          label: it.challan_no,
          value: it.challan_no
        }))
      );
    }

    const findId = Purchase?.find(
      (it) => it?.bill_no === e.value || it?.challan_no === e.value
    );
    if (findId) {
      dispatch(getSingleOrder({ id: findId._id, tag_number: 11 }));
      setformDate({
        order_date: findId?.order_date ? moment(findId.order_date).format('YYYY-MM-DD') : '',
        project_id: findId?.project?._id || '',
        party_id: findId?.party?._id || '',
        master_id: findId?.master?._id || '',
        receive_date: findId?.receive_date ? moment(findId.receive_date).format('YYYY-MM-DD') : '',
        transport_id: findId?.transport_details?._id || '',
        transport_date: findId?.transport_date ? moment(findId.transport_date).format('YYYY-MM-DD') : '',
        vehical_no: findId?.vehical_no || '',
        po_no: findId?.po_no || '',
        upload_pdf: findId?.pdf || '',
        payment_date: findId?.payment_date ? moment(findId.payment_date).format('YYYY-MM-DD') : '',
        payment_days: findId?.payment_days || '',
        lr_no: findId?.lr_no || '',
        lr_date: findId?.lr_date ? moment(findId.lr_date).format('YYYY-MM-DD') : '',
      });
      setData(getSinglePurchase?.items_details)
    }
  };

  const handleChallanChange = (e) => {
    const findBillno = Purchase?.find(
      (it) => it?.challan_no === e.value
    );
    if (findBillno) {
      setBillNo(findBillno?.bill_no || '');
    }

    setChallanNo(e.value);

    const findId = Purchase?.find(
      (it) => it?.bill_no === e.value || it?.challan_no === e.value
    );
    if (findId) {
      dispatch(getSingleOrder({ id: findId._id, tag_number: 11 }));
      setformDate({
        order_date: findId?.order_date ? moment(findId.order_date).format('YYYY-MM-DD') : '',
        project_id: findId?.project?._id || '',
        party_id: findId?.party?._id || '',
        master_id: findId?.master?._id || '',
        receive_date: findId?.receive_date ? moment(findId.receive_date).format('YYYY-MM-DD') : '',
        transport_id: findId?.transport_details?._id || '',
        transport_date: findId?.transport_date ? moment(findId.transport_date).format('YYYY-MM-DD') : '',
        vehical_no: findId?.vehical_no || '',
        po_no: findId?.po_no || '',
        upload_pdf: findId?.pdf || '',
        payment_date: findId?.payment_date ? moment(findId.payment_date).format('YYYY-MM-DD') : '',
        payment_days: findId?.payment_days || '',
        lr_no: findId?.lr_no || '',
        lr_date: findId?.lr_date ? moment(findId.lr_date).format('YYYY-MM-DD') : '',
      });
      setData(getSinglePurchase?.items_details)
    }
  };

  useEffect(() => {
    dispatch(getOrder({}));
    dispatch(getAdminItem());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGenMaster({ tag_id: 12 }));
    dispatch(getParty({ storeType: '1' }));
    dispatch(getProject())
    dispatch(getAdminTransport())
  }, []);

  useEffect(() => {
    if (challanNo) {
      findOneItems()
    }
  }, [challanNo]);

  const findOneItems = () => {

  }
  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    } else if (localStorage.getItem('VI_PRO') !== `${M_STORE}`) {
      toast.error('Access Denied. You do not have permission to view this product. Please contact your administrator for assistance.')
      navigate("/user/login");
    }
    fetchData();
  }, [navigate, disable, search, filter]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformDate({ ...formdata, [name]: value });
  };
  const handlePdf = (e) => {
    if (e?.target?.files[0]) {
      const allowedTypes = ["application/pdf"];
      const fileType = e.target.files[0].type;
      if (allowedTypes.includes(fileType)) {
        setDisable(true);
        const myurl = `${V_URL}/upload-image`;
        var bodyFormData = new FormData();
        bodyFormData.append('image', e?.target?.files[0]);
        axios({
          method: "post",
          url: myurl,
          data: bodyFormData,
        }).then((response) => {
          if (response.data.success === true) {
            const data = response?.data?.data?.pdf;
            setformDate({ ...formdata, upload_pdf: data });
          }
          setDisable(false);
        }).catch((error) => {
          toast.error(error.response?.data?.message)
          setDisable(false);
        })
      } else {
        toast.error("Invalid file type. Only PDFs are allowed.");
      }
    }
  }
  const fetchData = () => {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append("tag_number", 11);
    bodyFormData.append("search", search);
    bodyFormData.append("filter", JSON.stringify(filter));
    bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
    bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));
    dispatch(getOrder({ formData: bodyFormData }));
    setDisable(false);
  };

  return (
    <div className={`main-wrapper ${false ? "slide-nav" : ""}`}>
      <Header handleOpen={() => { }} />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/main-store/user/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right"></i>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/main-store/user/purchase-return-management">
                      Purchase Return
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right"></i>
                  </li>
                  <li className="breadcrumb-item active">
                    {"Add"} Purchase Return
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="custom-select-wpr input-block local-forms">
                        <label>Bill No.</label>
                        <Dropdown
                          value={billNo}
                          options={getUniqueBillNumbers()}
                          onChange={handleBillNoChange}
                          placeholder="Search Bill No"
                          filter
                          filterBy="label"
                          className="w-100 multi-prime-react model-prime-multi"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="custom-select-wpr input-block local-forms">
                        <label>Challan No.</label>
                        <Dropdown
                          value={challanNo}
                          options={Challanoption}
                          onChange={handleChallanChange}
                          placeholder="Search Challan No"
                          filter
                          filterBy="label"
                          // disabled={!billNo}
                          className="w-100 multi-prime-react model-pr ime-multi"
                        />
                      </div>
                    </div>
                    <div className={challanNo || billNo ? "d-block row" : "d-none"} >
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="input-block local-forms">
                          <label> Date <span className="login-danger">*</span></label>
                          <input className="form-control" type="date" name="order_date"
                            value={formdata.order_date} onChange={handleChange} />
                        </div>
                      </div>
                      <div className='row'>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label> Project Name <span className="login-danger">*</span> </label>
                            <select className="form-select form-control" name="project_id"
                              value={formdata?.project_id} onChange={handleChange}>
                              <option value="">Select Project</option>
                              {projects?.map((project) => (
                                <option key={project._id} value={project._id}>
                                  {project.name}
                                </option>
                              ))}
                            </select>
                            {/* {orderError?.project_id && <div className="error">{orderError.project_id}</div>} */}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label>
                              Party Name <span className="login-danger">*</span>
                            </label>
                            <select
                              className="form-select form-control"
                              name="party_id"
                              value={formdata?.party_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Party</option>
                              {parties?.map((party) => (
                                <option key={party.id} value={party._id}>
                                  {party.name}
                                </option>
                              ))}
                            </select>
                            {/* {orderError?.party_id && <div className="error">{orderError.party_id}</div>} */}
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label>Reciever Name <span className="login-danger">*</span>  </label>
                            <select className="form-select form-control" name="master_id"
                              value={formdata?.master_id} onChange={handleChange}  >
                              <option value="">Select Reciever</option>
                              {reciever?.map((e) => (
                                <option key={e._id} value={e._id}>
                                  {e.name}
                                </option>
                              ))}
                            </select>
                            {/* {orderError?.master_id && <div className="error">{orderError.master_id}</div>} */}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label>
                              Reciever Date <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="date"
                              name="receive_date"
                              value={formdata.receive_date}
                              onChange={handleChange}
                            />
                            {/* <div className='error'>{orderError?.receive_date}</div> */}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label>Transport <span className="login-danger">*</span></label>
                            <select className="form-select form-control" name="transport_id"
                              value={formdata?.transport_id} onChange={handleChange}>
                              <option value="">Select Transport</option>
                              {transport?.map((transport) => (
                                <option key={transport._id} value={transport._id}>
                                  {transport.name}
                                </option>
                              ))}
                            </select>
                            {/* {orderError?.transport_id && <div className="error">{orderError.transport_id}</div>} */}
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label> Transport Date</label>
                            <input className="form-control" type="date" name="transport_date"
                              value={formdata.transport_date} onChange={handleChange} />
                            {/* <div className='error'>{orderError?.transportDate}</div> */}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label> Vehicle </label>
                            <input className="form-control" type="text" name="vehical_no"
                              value={formdata.vehical_no} onChange={handleChange}
                            />
                            {/* <div className='error'>{orderError?.vehical_no}</div> */}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label>PO No. </label>
                            <input className="form-control" type="text"
                              name="po_no" value={formdata.po_no} onChange={handleChange} />
                            {/* <div className='error'>{orderError?.po_no}</div> */}
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-top-form">
                            <label className="local-top">PDF <span className="login-danger">*</span></label>
                            <div className="settings-btn upload-files-avator">
                              <label htmlFor="pdfFile" className="upload">Choose PDF File(s)</label>
                              <input type="file" id="pdfFile" onChange={handlePdf} accept=".pdf" className="hide-input" />
                            </div>
                            {formdata.upload_pdf ? (
                              <a href={formdata.upload_pdf} target='_blank'>
                                <img src='/assets/img/pdflogo.png' />
                              </a>
                            ) : null}
                            {/* {orderError?.challan && <div className="error">{orderError.upload_pdf}</div>} */}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label> Payment Date</label>
                            <input className="form-control" type="date" name="payment_date"
                              value={formdata?.payment_date} onChange={handleChange} />
                            {/* {orderError?.payment_date && <div className="error">{orderError.payment_date}</div>} */}
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label>Payment Days.</label>
                            <input className="form-control" type="number"
                              disabled />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label> LR No </label>
                            <input className="form-control" value={formdata.lr_no} name='lr_no' onChange={handleChange} />
                            {/* {orderError?.lr_no && <div className="error">{orderError.lr_no}</div>} */}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <div className="input-block local-forms">
                            <label> LR Date</label>
                            <input className="form-control" type="date" name="lr_date"
                              value={formdata?.lr_date} onChange={handleChange} />
                            {/* {orderError?.lr_date && <div className="error">{orderError.lr_date}</div>} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <Return_Table
                    headers={headers}
                    data={challanNo || billNo ? data : []}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="col-12 text-end">
                    <div className="doctor-submit text-end">
                      <button
                        type="button"
                        className="btn btn-primary mx-2"
                      // onClick={handleBack}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => navigate("/main-store/user/purchase-return-management")}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
export default ManagePurchaseReturn