export const statusForProcurement = [
    { value: 1, label: 'Pending' },
    { value: 2, label: 'Approved By Admin' },
    { value: 3, label: 'Rejected By Admin' },
    { value: 4, label: 'Completed' },
]

export const statusForQc = [
    { value: 1, label: 'Pending' },
    { value: 2, label: 'Send to QC' },
    { value: 3, label: 'Approved By QC' },
    { value: 4, label: 'Rejected' },
]

export const adminRequest = [
    { value: 1, label: 'Pending' },
    { value: 5, label: 'Completed' },
]