import React, { createContext, useContext, useEffect, useState } from 'react';
import { menuAccessConfig } from '../Pages/Users/Components/MenuAccess/MenuAccess';

const RoleAccessContext = createContext();

export const RoleAccessProvider = ({ children }) => {
    const [userRole, setUserRole] = useState(localStorage.getItem('ERP_ROLE') || null);
    useEffect(() => {
        const storedRole = localStorage.getItem('ERP_ROLE');
        if (storedRole !== userRole) {
            setUserRole(storedRole);
        }
    }, [userRole]);

    const hasAccess = (item) => menuAccessConfig[item]?.includes(userRole);

    return (
        <RoleAccessContext.Provider value={{ userRole, setUserRole, hasAccess }}>
            {children}
        </RoleAccessContext.Provider>
    );
};

export const useRoleAccess = () => useContext(RoleAccessContext);
