import React from 'react'
import Admin from './Routes/Admin'
import Home from './Routes/Home'
import Store from './Routes/Store'
import ProductStore from './Routes/ProductStore'
import Index from './Routes/erp/Index'
import UsersRoute from './Routes/Users/UsersRoute'
import { RoleAccessProvider } from './Context/RoleAccessContext'


const App = () => {
  return (

    <>
      <RoleAccessProvider>
        <>
          <Home />
          <Store />
          <ProductStore />
          <Index />
          <UsersRoute />
          <Admin />
        </>
      </RoleAccessProvider>
    </>
  )
}

export default App